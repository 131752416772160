import { useMemo } from 'react';
import styles from './styles.module.scss';
import { Image as CoreImage, ErrorCard } from '@starsoft/common/components';
import { useCoins } from '@starsoft/common/api';
import { Coin, CoinType } from '@starsoft/common/models';

export default function Coins() {
  const { coins, isPending, error, refetch } = useCoins();
  const cryptoCoins: Coin[] = useMemo(() => {
    if (!coins) {
      return [];
    }

    return coins.filter(coin => coin.type === CoinType.Crypto);
  }, [coins]);

  const CoinsImage: JSX.Element[] = useMemo(
    () =>
      cryptoCoins?.map(coin => (
        <CoreImage
          className={styles.container__coin}
          width={40}
          height={40}
          key={`crypto_coin_${coin.id}`}
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/coins/rounded/${coin.image}`}
          alt={coin.name}
        />
      )),
    [cryptoCoins],
  );

  const Skeletons: JSX.Element[] = useMemo(
    () =>
      Array.from({ length: 15 })?.map((_, i) => (
        <div
          className={`${styles.container__coin} ${styles['container__coin--skeleton']}`}
          key={`crypto_coin_skeleton_${i}`}
        />
      )),
    [],
  );

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  return (
    <div className={styles.container}>{isPending ? Skeletons : CoinsImage}</div>
  );
}
