import {
  Coin,
  PaginatedRequest,
  PaginatedResponse,
} from '@starsoft/common/models';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UseCoinsQueryKey } from './props';
import { AxiosInstance, AxiosResponse } from 'axios';

export async function fetchCoins(
  { pageParam = 1, queryKey }: QueryFunctionContext<UseCoinsQueryKey>,
  axiosInstance: AxiosInstance,
): Promise<PaginatedResponse<Coin>> {
  const { limit }: PaginatedRequest = queryKey[1];

  const response: AxiosResponse<PaginatedResponse<Coin>> =
    await axiosInstance.get<PaginatedResponse<Coin>>('/coins', {
      params: {
        page: pageParam,
        limit,
      },
    });

  return response.data;
}
